.cardContainer {
    width: 61.5625vw; /* 1182px 61.5625vw*/
    min-height: 21vh; /* 改为最小高度，允许内容扩展 */
    position: relative;
}

.cardContainer .backgroundImage {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(126, 255, 255, 0.50);
    box-shadow: 0px 0px 80px 10px rgba(0, 0, 0, 0.75);
    position: relative;
    display: flex; /* 添加flex布局 */
    flex-direction: column; /* 垂直排列子元素 */
}

.cardContainer .mask {
    height: 100%;
    border-radius: 5px;
    background: rgba(13, 17, 23, 0.80);
    position: absolute;
    z-index: 1;
}

.cardContainer #mask1 {
    width: 25%; /* 295.5px */
}

.cardContainer #mask2 {
    width: 50%; /* 591px */
}

.cardContainer #mask3 {
    width: 100%; /* 1182px */
}

.cardContainer .content {
    padding: 3.486vh 1.5625vw 2.723vh 1.5625vw; /* 32px 30px 25px 30px */
    position: relative;
    z-index: 2;
    display: flex; /* 添加flex布局 */
    flex-direction: column; /* 垂直排列子元素 */
    flex-grow: 1; /* 允许内容区域根据需要增长 */
}

.cardContainer .content .title {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 3.16vh; /* 28.748px */
    letter-spacing: 1px;
    margin-bottom: 0.545vh; /* 5px */
    word-wrap: break-word; /* 允许长文本换行 */
}

.cardContainer .content .author {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 2.51vh; /* 22.998px */
    margin-bottom: 1.85vh; /* 17px */
    word-wrap: break-word; /* 允许长文本换行 */
}

.cardContainer .content .publishTitle {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 2.51vh; /* 22.998px */
    margin-bottom: 1.31vh; /* 12px */
    word-wrap: break-word; /* 允许长文本换行 */
}

.cardContainer .content .bottomButtonsContainer {
    margin-top: auto; /* 将按钮组推到内容区底部 */
}

.cardContainer .content .bottomButtonsContainer .button {
    display: inline;  
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 2.51vh; /* 22.998px */
    letter-spacing: 1.12px;
    margin-right: 0.9375vw; /* 18px */
}

.cardContainer .content .bottomButtonsContainer .buttonEnabled {
    color: #3186FF;
    cursor: pointer;
}

.cardContainer .content .bottomButtonsContainer .buttonDisabled {
    color: #46494E;
}

