.affiliation {
    min-width: 20.3125vw; /* 390px */
    width: 100%;
}

.unitContainer {
    display: grid;
    grid-template-columns: 2.47vw 1fr; /* 47.418px 328px */
    grid-template-rows: 2.18vh 1fr; /* 20px 15px */
    grid-column-gap: 0.52vw; /* 10px */
    grid-row-gap: 0px;
    margin-bottom: 1.3072vh; /* 12px */
}

.iconAndSplitContainer {
    grid-area: 1 / 1 / 3 / 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.iconAndSplitContainer .icon {
    width: 2.113vw; /*40.568px*/
    aspect-ratio: 1 / 1;
}

.iconAndSplitContainer .splitLine {
    width: 0.5px;
    height: 30px;
    background: #FFF;
}

.lab {
    grid-area: 1 / 2 / 2 / 3;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    width: 100%;
}

.school {
    grid-area: 2 / 2 / 3 / 3;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.positionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.6771vw; /* 13px */
}

.positionsContainer .position {
    border-radius: 3px;
    background: #FFF;
    height: 100%; /*19px*/

    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.5px 5px 0px 5px;
}